













































































































































































































































































import Component from 'vue-class-component';
import Vue from 'vue';
import availableIcons from '@/constants/icons';
import { BattleData, BattleMarker, BattleFieldData } from '@/interfaces/group/battle';
import FirebaseRealtime from '@/classes/firebaseRealtime';
import FirebaseGateway from '@/classes/firebaseGateway';
import FirebaseSnapshotListener from '@/classes/firebaseSnapstListener';
import ClassCard from '@/components/character/classCard.vue';
import { ClassCardData } from '@/interfaces/character/classCard';
import Character, { CharacterShortData, CharacterExportData } from '@/classes/character/character';
import { Watch } from 'vue-property-decorator';
import { type } from 'os';

@Component({ components: { ClassCard } })
export default class BattleComponent extends Vue {
  public activeItem = -1;
  private availableIcons: string[] = availableIcons;
  private newMarker: BattleMarker | null = null;
  private uploadError: string | null = null;
  private battleImageUploading = false;
  private showBattleImageUpload = false;
  private selectedFile: File | null = null;
  private battleImageName: string = '';
  private battleImagesLoading = false;
  private bottomSheetModel = false;
  private showMenu = false;
  private menuX = 0;
  private menuY = 0;
  private newMarkerX = 0;
  private newMarkerY = 0;
  private fab = false;
  private selectedCharacter: string | null = null;
  private addClassIndex: number | null = null;
  private classCardToAdd: string = '';

  private colorOptions = [
    { text: 'Grün', value: 'green' },
    { text: 'Rot', value: 'red' },
    { text: 'Schwarz', value: 'black' },
    { text: 'Blau', value: 'blue' },
    { text: 'Gelb', value: 'yellow' },
  ];

  get currentUser() {
    return this.$store.getters.userId;
  }

  get isAdmin() {
    return this.$store.getters.isGroupMaster;
  }

  get battleState(): BattleData | null {
    return this.$store.getters.battleState;
  }

  get battleUrl() {
    if (this.battleState === null) {
      return null;
    } else {
      return this.battleState.imageUrl;
    }
  }

  get markers() {
    if (this.battleState === null || !this.battleState.markers) {
      return [];
    } else {
      return this.battleState.markers;
    }
  }

  get currentGroupId(): string | null {
    return this.$store.getters.currentGroup;
  }

  get availableBattlefields(): BattleFieldData[] {
    return this.$store.getters.battleImages;
  }

  get players(): CharacterShortData[] {
    return this.$store.getters.characterShorts;
  }

  get characters(): CharacterShortData[] {
    return this.$store.getters.characterShorts;
  }

  get bottomSheetLoading() {
    return this.classCardsLoading || this.characterLoading;
  }

  private currentMarkerId: string | null = null;
  private isMoveActive = false;
  private isCreateActive = false;
  private isZoomActive = false;
  private isZoomed = false;
  private zoomOrigin: { x: number | null; y: number | null } = { x: null, y: null };
  private showSettingsDialog = false;
  private showBattlefieldDialog = false;
  private battlefieldModel: number | undefined | null = null;
  private widthScalingActive = false;
  private heightScalingActive = false;

  // Loading signifiers
  private classCardsLoading = true;
  private characterLoading = true;

  public mounted() {
    this.$store.dispatch('loadGroup').then(() => {
      // Now we can watch the characters
      this.$store.dispatch('watchCharacters');
      this.characterLoading = false;
    });
    this.$store.dispatch('loadGroupData');
    this.$store.dispatch('loadClassCards').then(() => {
      this.classCardsLoading = false;
    });

    this.$store.dispatch('loadBattle');
    window.addEventListener('resize', this.onResize);
    window.addEventListener('keypress', this.onKeyPress);
    window.addEventListener('keyup', this.onKeyUp);
  }

  public beforeDestroy() {
    FirebaseRealtime.clearListeners();
    FirebaseSnapshotListener.unsubscribe();
    window.removeEventListener('resize', this.onResize);
    window.removeEventListener('keypress', this.onKeyPress);
    window.removeEventListener('keyup', this.onKeyUp);
  }

  private dialogChange(isOpen: boolean) {
    if (!isOpen) {
      this.activeItem = -1;
    }
  }

  private battleDialogChange(isOpen: boolean) {
    if (!isOpen) {
      this.activeItem = -1;
    }
  }

  private closeBattleDialog() {
    this.showBattlefieldDialog = false;
    this.activeItem = -1;
  }

  private adjustSize(marker: BattleMarker, event: WheelEvent) {
    if (this.currentMarkerId === null) {
      return;
    }
    event.stopPropagation();
    event.preventDefault();
    const markerClone: BattleMarker = JSON.parse(JSON.stringify(marker));

    if (this.widthScalingActive) {
      if (typeof markerClone.width === 'undefined') {
        markerClone.width = 1;
      }
      if (event.deltaY > 0) {
        if (markerClone.width > 0.1) {
          markerClone.width -= 0.2;
        }
      } else {
        markerClone.width += 0.2;
      }
    }

    if (this.heightScalingActive) {
      if (typeof markerClone.height === 'undefined') {
        markerClone.height = 1;
      }
      if (event.deltaY > 0) {
        if (markerClone.height > 0.1) {
          markerClone.height -= 0.2;
        }
      } else {
        markerClone.height += 0.2;
      }
    }

    if (!this.widthScalingActive && !this.heightScalingActive) {
      if (event.deltaY > 0) {
        if (markerClone.size > 0.1) {
          markerClone.size -= 0.05;
        }
      } else {
        markerClone.size += 0.05;
      }
    }

    this.$store.dispatch('updateMarker', markerClone);
  }

  private toggleZoom() {
    this.isZoomActive = !this.isZoomActive;
  }

  private toggleBattleFieldDialog() {
    this.showBattlefieldDialog = !this.showBattlefieldDialog;
    if (this.showBattlefieldDialog) {
      this.$store.dispatch('loadBattleImages').then(() => {
        this.battleImagesLoading = false;
      });
    }
  }

  private battleImageClick(event: MouseEvent) {
    if (this.isMoveActive) {
      this.moveMarker(event);
      return;
    }

    if (this.isZoomActive) {
      this.zoomOrigin.x = event.offsetX;
      this.zoomOrigin.y = event.offsetY;
      this.isZoomed = !this.isZoomed;
      return;
    }

    this.deselectMarker();
  }

  private onResize() {
    this.$forceUpdate();
  }

  private onKeyPress(event: KeyboardEvent) {
    if (event.key === 'b') {
      this.widthScalingActive = true;
    } else if (event.key === 'h') {
      this.heightScalingActive = true;
    }
  }

  private onKeyUp(event: KeyboardEvent) {
    if (event.key === 'b') {
      this.widthScalingActive = false;
    } else if (event.key === 'h') {
      this.heightScalingActive = false;
    }
  }

  private onRightClick(event: MouseEvent, selectedMarker: BattleMarker | null) {
    event.preventDefault();
    event.stopPropagation();
    this.menuX = event.clientX;
    this.menuY = event.clientY;

    // In case we later want to create a marker, save the coordinates
    this.newMarkerX = event.offsetX / (event.srcElement as HTMLElement).offsetWidth;
    this.newMarkerY = event.offsetY / (event.srcElement as HTMLElement).offsetHeight;

    if (selectedMarker !== null && this.currentMarkerId !== selectedMarker.id) {
      this.toggleSelectedMarker(selectedMarker);
    }
    this.showMenu = true;
  }

  private getMarkerClasses(marker: BattleMarker) {
    const classes: any = {
      'elevation-24': this.currentMarkerId === marker.id.toString(),
      'selected': this.currentMarkerId === marker.id.toString(),
      'selectable': marker.userId === this.currentUser,
      'hasIcon': typeof marker.icon !== 'undefined' && marker.icon !== null,
      'rounded': marker.round === true,
    };

    const label = marker.label.trim().toLowerCase();
    if (this.players.findIndex((player) => player.name.split(' ')[0].toLowerCase() === label) >= 0) {
      classes[label] = true;
      classes.ispc = true;
    }

    if (marker.color) {
      classes['border-' + marker.color] = marker.color;
    }

    return classes;
  }

  private getMarkerStyles(marker: BattleMarker) {
    const styles: any = {
      left: marker.x * 100 + '%',
      top: marker.y * 100 + '%',
    };
    let xTranslate = '-60px';
    if (marker.width) {
      styles.width = 120 * marker.width + 'px';
      xTranslate = (-1 * (120 * marker.width)) / 2 + 'px';
    }
    let yTranslate = '-60px';
    if (marker.height) {
      styles.height = 120 * marker.height + 'px';
      yTranslate = (-1 * (120 * marker.height)) / 2 + 'px';
    }
    styles.transform =
      'translate(' + xTranslate + ',' + yTranslate + ') scale(' + this.getFinalMarkerScale(marker) + ')';
    return styles;
  }

  private showCreateMarkerDialog() {
    const id = Math.round(Math.random() * 1000000).toString();
    this.newMarker = {
      id,
      userId: this.currentUser,
      label: '',
      size: 0.5,
      icon: null,
      color: null,
      x: 0,
      y: 0,
    };
    this.showSettingsDialog = true;
  }

  private abortCreateClick() {
    this.activeItem = -1;
    this.showSettingsDialog = false;
  }

  private onCreateMarkerClick() {
    this.isCreateActive = true;
    this.showSettingsDialog = false;
    this.createMarker();
  }

  private removeMarker() {
    if (this.currentMarkerId === null) {
      throw new Error('battlefieldModel is undefined');
    }
    this.$store.dispatch('removeMarker', this.currentMarkerId).then(() => {
      this.activeItem = -1;
      this.currentMarkerId = null;
    });
  }

  private newBattle() {
    if (typeof this.battlefieldModel === 'undefined' || this.battlefieldModel === null) {
      throw new Error('battlefieldModel is undefined');
    }
    const imageUrl = this.availableBattlefields[this.battlefieldModel].url;
    this.$store.dispatch('newBattle', imageUrl).then(() => {
      this.closeBattleDialog();
      this.battlefieldModel = null;
    });
  }

  private uploadBattleImage() {
    if (this.selectedFile === null) {
      throw new Error('File is null');
    }
    // Clear the error
    this.uploadError = null;
    const fileName = this.selectedFile.name;
    this.battleImageUploading = true;

    new FirebaseGateway()
      .uploadFile(this.selectedFile, '/groups/' + this.currentGroupId + '/battleImages/', false)
      .then((downloadURL) => {
        if (downloadURL === null || typeof downloadURL === 'undefined') {
          throw new Error('DownloadUrl not present after upload');
        }
        const battleImage: BattleFieldData = {
          url: downloadURL,
          name: this.battleImageName,
        };
        this.$store.dispatch('uploadBattleImage', battleImage).then(() => {
          this.showBattleImageUpload = false;
          this.battleImageUploading = false;
          this.battleImageName = '';
          this.selectedFile = null;
        });
      })
      .catch((error) => {
        if (error instanceof Error && error.message === 'file_exists') {
          this.uploadError = 'Datei mit diesem Namen existiert bereits';
        } else {
          this.uploadError = error;
        }
      });
  }

  private createMarker() {
    if (this.newMarker === null) {
      throw new Error('newMarker is null');
    }
    this.newMarker.x = this.newMarkerX;
    this.newMarker.y = this.newMarkerY;
    this.$store.dispatch('updateMarker', this.newMarker);
    this.isCreateActive = false;
    this.activeItem = -1;
    this.currentMarkerId = null;
  }

  private moveMarker(event: MouseEvent) {
    if (this.currentMarkerId === null) {
      return;
    }

    const markerClone: BattleMarker = JSON.parse(JSON.stringify(this.markers[this.currentMarkerId]));

    markerClone.x = event.offsetX / (event.srcElement as HTMLElement).offsetWidth;
    markerClone.y = event.offsetY / (event.srcElement as HTMLElement).offsetHeight;

    this.$store.dispatch('updateMarker', markerClone);
  }

  private getFinalMarkerScale(marker: BattleMarker) {
    // Take fullHD as base
    const factor = window.innerWidth / 1920;
    return marker.size * factor;
  }

  private toggleSelectedMarker(marker: BattleMarker) {
    if (marker.userId !== this.currentUser) {
      return;
    }

    // Reset any states
    this.isMoveActive = false;
    this.isCreateActive = false;

    if (this.currentMarkerId === marker.id) {
      this.currentMarkerId = null;
    } else {
      this.currentMarkerId = marker.id;
    }
  }

  private deselectMarker() {
    // Reset any states
    this.isMoveActive = false;
    this.isCreateActive = false;
    this.currentMarkerId = null;
  }

  private getCardFromStore(classCardId) {
    if (classCardId === null) {
      return null;
    }
    return this.$store.getters.getClassCardsByIds([classCardId])[0];
  }
}
