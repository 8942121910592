























import Component from 'vue-class-component';
import Vue from 'vue';
import { Prop } from 'vue-property-decorator';

@Component
export default class ClassCard extends Vue {
  public exampleTitles: string[] = ['Netzwerker', 'Racheengel'];

  @Prop()
  public title!: string;

  @Prop()
  public imageUrl!: string;

  @Prop()
  public description!: string;

  @Prop()
  public className!: string;

  @Prop({ default: false })
  public mini!: boolean;
}
